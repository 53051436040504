import React, { useState } from 'react'

import H5 from './H5'
import Modal from './CalculadoraPriceStyle'
import { Scrollbars } from 'react-custom-scrollbars'
import EnvieSuaMensagem from '../components/EnvieSuaMensagem'

const sendMensagem = [
  {
    label: 'Nome',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'E-mail',
    name: 'your-email',
    type: 'email',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    required: true
  }
]

const WannaBuyModal = ({
  visible,
  onClose
}) => {
  const [status, setStatus] = useState('default')
  const onCloseModal = () => {
    setStatus('default')
    onClose()
  }
  const statuses = {
    default: (
      <>
        <Scrollbars
          style={{ height: 360 }}
        >
          <H5
            selected
            on='onSurface'
            variant='highEmphasis'
            align='center'
            mb={[3]}
          >
            Consulte-nos
          </H5>
          <EnvieSuaMensagem
            fields={sendMensagem}
            formId={4042}
            submitText='Enviar'
          />
        </Scrollbars>
      </>
    ),
  }

  return (
    <Modal
      open={visible}
      hasArrowBack={['callForm', 'messageForm'].includes(status)}
      hideIcons={status === 'sent'}
      onClose={onCloseModal}
      onBack={() => setStatus('default')}
      style={{
        zIndex: 2000,
      }}
    >
      {statuses[status]}
    </Modal>
  )
}

export default WannaBuyModal
