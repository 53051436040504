import React from 'react'
import MuiModal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Close from '@material-ui/icons/Close'

const useModalStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    padding: '50px 16px',
    top: 70,
    boxSizing: 'border-box'
  },
}))

const Modal = ({
  children,
  hasArrowBack,
  onClose,
  hideIcons,
  onBack,
  ...rest
}) => {
  const classes = useModalStyles()

  return (
    <MuiModal
      {...rest}
      onClose={onClose}
    >
      <div
        style={{
          padding: 16,
          display: 'flex',
          justifyContent: 'center',
          boxSizing: 'border-box',
          width: '100%',
          outline: 0,
          position: 'relative'
        }}
      >
        <div className={classes.paper}>
          {!hideIcons && hasArrowBack &&
            <ArrowBack
              style={{
                position: 'absolute',
                top: 16,
                left: 16,
                width: 24,
                height: 24,
                color: 'rgba(0, 0, 0, 0.54)',
                cursor: 'pointer'
              }}
              onClick={onBack}
            />
          }
          {!hideIcons && !hasArrowBack &&
            <Close
              style={{
                position: 'absolute',
                top: 16,
                right: 16,
                width: 24,
                height: 24,
                color: 'rgba(0, 0, 0, 0.54)',
                cursor: 'pointer'
              }}
              onClick={onClose}
            />
          }
          {children}
        </div>
      </div>
    </MuiModal>
  )
}

export default Modal
